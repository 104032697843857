<template>
    <div>
        <div id="heade-action" class="w-100 bg-white sticky-top p-3" style="align-items:center;justify-content:center;">
            <div>
                <img :src="$api.ste.logo" class="logo"/>
            </div>
            <div class="ml-auto">                
            </div>
            <div class="ml-auto" v-if="phone != false">                
                <b-button class="ml-2" size="sm" :variant="call_color" :href="`tel:${phone}`">
                    <i class="fas fa-phone"></i><br/>
                    <span class="h6">Appelez nous</span>
                </b-button>
            </div>
        </div>
        <b-container v-if="ready"  class="mt-3" style="margin-bottom:50px">            
            <b-row class="justify-content-center">
                <b-col cols="12">
                    <div class="p-2 text-center mt-5 mb-5">
                        <span class="text-white h2"></span>
                    </div>
                </b-col>
                <b-col lg="9" md="11">  
                    <b-card class="card-custom" v-if="!send">
                        <div class="text-center mb-5">
                            <p class="h5">
                                Formulaire de Parrainage<br/>
                                <small>(Merci d'indiquer les informations de la personne que vous souhaiteriez parrainer)</small>
                            </p>
                        </div>
                        <form ref="form">
                            <b-row class="mt-5 justify-content-center">
                            <b-col md="4" class="mb-4">
                                <b-form-input type="text" v-model="contact.nom" placeholder="Nom" required/>                                
                            </b-col>
                            <b-col md="4" class="mb-4">
                                <b-form-input type="text" v-model="contact.prenom" placeholder="Prénom" required/>                                                                
                            </b-col>
                            <!-- <b-col md="4" class="mb-4">
                                <Input type="text" v-model="contact.email" placeholder="Email" :required="true"/>
                            </b-col> -->
                            <b-col md="4" class="mb-4">
                                <b-form-input type="text" v-model="contact.telephone_1" placeholder="Téléphone" minlength="10" maxlength="10" required/>                                                                
                            </b-col>
                            <!-- <b-col md="4" class="mb-4">
                                <Input type="text" v-model="contact.cp" placeholder="Code postale" :required="true" />
                            </b-col> -->
                            <b-col md="12 mt-5">
                                <p class="h6 text-primary">40€ de chèques cadeaux pour tout parrainage abouti.</p>
                            </b-col>
                        </b-row>
                        </form>
                        <div class="text-center mt-5">
                            <b-button variant="primary" @click="save">
                                <span>Envoyez</span>
                                <i v-if="contactLoad" class="fas fa-spin fa-spinner"></i>
                            </b-button>
                        </div>
                    </b-card>
                    <b-card class="card-custom" v-else>
                        <div class="">
                            <p class="h5 mb-5">Merci, votre parrainage a bien été enregistré !</p>
                            <div class="text-center">
                                <b-button variant="light-info" @click="reload()">
                                    <span class="h6">Un autre parrainage ?</span>                                    
                                </b-button>
                                <b-button class="ml-2" :variant="call_color" :href="`tel:${phone}`">                                    
                                    <span class="h6">Appelez nous</span>
                                </b-button>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>            
        </b-container>                        
    </div>
</template>
<script>        
    export default {
        name: "RDV",
        //components: {Input},
        computed: {},
        data() {
            return {
                ready: false,                
                phone:false,                
                call_color: 'primary',
                send:false,  
                contactLoad:false, 
                contact:{                    
                    nom:null,
                    prenom:null,
                    email:null,
                    telephone_1:null,
                    code:null
                }             
            }
        },
        beforeMount(){            
        },
        mounted() {  
            if(this.$route.params.code != undefined){
                this.contact.code = this.$route.params.code;
            }   
            if(this.$route.query.phone != undefined){
                this.phone = this.$route.query.phone
            }       
            setInterval(() => {
                this.call_color = this.call_color === 'primary' ? 'light-danger' : 'primary';
            },2000)                              
            this.ready = true;           
        },        
        methods: {                        
            save(){                
                if(this.contactLoad === true){
                    return true;
                }
                if(!this.checkForm()){
                    return false;
                }
                this.contactLoad = true;
                this.$api.ajax('/source/parrainage', this.contact, res => {
                    if(res.status === true){
                        this.send = true;
                    }
                    this.contactLoad = false;
                })
            },
            checkForm(){
                var form = this.$refs.form;
                var fields = form.querySelectorAll("[required='required']");
                var valide = true;
                fields.forEach((item) => {
                    if(item.reportValidity() === false){
                        item.classList.add("need-validation");
                        valide = false;
                    }else{          
                        item.classList.remove("need-validation");
                    };
                });
                if(valide === false){
                    this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
                        title: 'Champs',
                        variant: 'warning',
                        solid: true
                    });
                    this.tabIndex = 0;
                    return false;
                }
                return true;
            },
            reload(){
                location.reload()
            }
        },        
    }        
</script>
<style lang="scss">    
    .logo {
        max-width: 250px;
        
    }        
    #heade-action {
        display: flex;
    }
    .need-validation {
        border-bottom:none !important;
        border: solid 2px red !important;
        border-radius: 10px !important
    }
    .form-control {
        border:none;
        border-radius:0px;
        border-bottom: #416eaf solid 2px;
    }
    .form-control::placeholder {
        color:rgb(59, 59, 59);
        font-size: 14px;
    }
    @media (max-width: 768px){
        #heade-action {
            display: block;
            text-align: center;
        } 
        #heade-action div {
            margin-bottom: 10px;
        }
        #heade-action .btn {
            padding: 0.50rem 0.70rem;
            font-size: 0.925rem;
            line-height: 1.35;
            border-radius: 0.85rem;
        }                
    }            
</style>